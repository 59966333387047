import React from 'react';
import { Container, Section } from '../../global';

const ParentItems = () => (
    <Section id="parent-items">
        <Container>
            <h2>Items To Bring</h2>
            <ul>
                <li>- An extra set of clothes (socks, underwear, pants/shorts, and shirt) will remain in your child’s cubby at school. These clothes should be switched out regularly due to size and weather changes. </li>
                <li>- We provide sheets and a blanket at nap time. If you would like to send a blanket with your child you are welcome to.</li>
            </ul>
        </Container>
    </Section>
);

export default ParentItems;