import React from 'react';
import { Container, Section } from '../../global';

const ParentInfo = () => (
  <Section id="parent-info">
    <Container>
      <ul>
        <li>
          If you have any questions or concerns regarding your child's care,
          please speak to their teacher or center director. Do not hesitate to
          ask questions. We are here to help you and your family. Our goal is to
          provide a safe and happy environment for each child. When parents
          practice these guidelines and work cooperatively with the center, this
          makes it easier to do.
        </li>
      </ul>
    </Container>
  </Section>
);

export default ParentInfo;
