import React from 'react';
import {Container, Section} from '../global';
import ParentInfo from './ParentContent/ParentInfo';

const Parents = () => (
    <Section id="parents">
        <Container>
        <h1>PARENTS</h1>
        <ParentInfo/>
        </Container >
    </Section>
);

export default Parents;
