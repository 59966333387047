import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/sections/Footer';
import React from 'react';
import Calendar from '../components/sections/Calendar/Calendar';
import Parents from '../components/sections/Parents';
import ParentItems from '../components/sections/ParentContent/ParentItems';
import ParentChildSafety from '../components/sections/ParentContent/ParentChildSafety';
import ParentDontForget from '../components/sections/ParentContent/ParentDontForget';
import ParentKeepInMind from '../components/sections/ParentContent/ParentKeepInMind';

const ParentsPage = () => (
  <Layout>
    <Navbar/>
    <div class="main-content parent-main-content">
        <Parents/>
        <ParentItems/>
        <ParentDontForget/>
        <ParentChildSafety/>
        <ParentKeepInMind/>
        <h2 class="calendar-title">Calendar</h2>
        <Calendar/>
    </div>
    <Footer/>
  </Layout>
);

export default ParentsPage;
