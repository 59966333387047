import React from 'react';
import { Container, Section } from '../../global';

const ParentKeepInMind = () => (
    <Section id="parent-keep-in-mind">
        <Container>
            <h2>Keep in mind</h2>
            <ul>
                <li>- Your child is at the center to learn through play. This means <b>they will get dirty</b>. Please do not send your children in clothing that you would want to see ruined. </li>
                <li>- The center does not allow toys from home to be brought in. This includes toys with weapons on them, action figures, etc. Even the items kept in children’s cubbies can create problems. Alternative transitional items parents may use can be; books, blankets, stuffed animals to be used during naptime.</li>
                <li>- During summer months, please put sunscreen on your child each morning. Be sure to cover their entire torso so they are protected during water play.</li>
            </ul>
        </Container>
    </Section>
);

export default ParentKeepInMind;