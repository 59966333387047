import React from 'react';
import { Container, Section } from '../../global';

const ParentChildSafety = () => (
    <Section id="parent-child-safety">
        <Container>
            <h2>For their safety, we discourage wearing</h2>
            <ul>
                <li>- Open-toed shoes. Children may hurt themselves outside while playing outdoors.</li>
                <li>- Dress shoes. These shoes may be uncomfortable, unsafe for climbing, or slippery.</li>
                <li>- Jackets, sweatshirts, or hoods with string. These can be a choking hazard.</li>
                <li>- Dresses or skirts unless they have shorts on underneath. This is for their personal privacy.</li>
            </ul>
        </Container>
    </Section>
);

export default ParentChildSafety;