import React from 'react';
import { Container, Section } from '../../global';

const ParentDontForget = () => (
    <Section id="parent-dont-forget">
        <Container>
            <h2>Don’t forget</h2>
            <ul>
                <li>- To sign your child in and out daily. </li>
                <li>- Empty your child’s cubby each day. Their work is important to them. </li>
                <li>- Important information will be sent home through your child’s cubby. </li>
                <li>- If your child will be absent for any reason, you must notify the center.</li>
                <li>- Label your child’s clothing and belonging. The teachers try their best to keep things in order, but there may be multiple items being washed at once. </li>
            </ul>
        </Container>
    </Section>
);

export default ParentDontForget;